<template>
	<div class="search-wrapper fav-search"  @click="toggleModal()" v-show="!isMobile">
		<img class="search-icon fav-search-icon"  src="../../../static/images/icons/icon_search.png"  alt="" />
		<input class="search-input" type="text" :placeholder="$t('SEARCH')"/>
	</div>
  <div class="fav-card__container flex-col" :class="!isNoData ? 'display-flex' : 'display-flex-center'">
    <p class="fav-subtitle" v-if="isNoData">
			{{ isMobile ? $t("TAP_ON") : $t("CLICK_ON") }}
			<img class="fav-icon__sample" src="../../../static/images/icons/icon-search-grey.png"  alt="" />{{ isMobile ? $t("THEN_TAP") : $t("THEN_CLICK") }}
			<img class="fav-icon__sample" src="../../../static/images/icons/icon-star-grey.png" alt="" />{{ $t("ADD_COMPETITION") }}
    </p>
    <template v-if="isNoData">
    	<FavCard type="sample" name="League A" />
    </template>
    <template v-else>
      <FavCard
        v-for="c in favList"
        :key="c.id"
        :keyName="c.key"
        :type="isTab"
        :countryKey="c.countryKey"
        :name="c.name"
        :id="c.id"
        :logo="c.logo"
        :isFav="c.isFav"
        @favHandler="favHandler"
      />
    </template>
  </div>
  <Modal @closeModal="toggleModal" :tabName="isTab" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "./components/Modal.vue";
import FavCard from "./components/FavCard.vue";

export default {
  components: {
    Modal,
    FavCard,
  },
  data() {
    return {
		isShowModal: false,
		isTab: "COMPETITIONS",
		favList: "",
		isNoData: false,
    };
  },
  watch: {
 	  userSwitchCountryLang: {
      deep: true,
      handler(newVal, oldVal) {
          this.init();
      },
    },
  },
  mounted() {
    this.commonVerifyLogin();
    if (this.isLogin) {
      this.init();
    }
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo", "isMobile", "isShowFavModal",'userSwitchCountryLang']),
  },
  methods: {
    ...mapActions([
      "getFavLeagueList",
      "unFavLeague",
      "commonVerifyLogin",
      "toastrFavChangeEvent",
      "toggleFavModal",
    ]),

    init() {
      this.getFavLeagueListData();
    },

    async getFavLeagueListData() {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
      };
  		
      const result = await this.getFavLeagueList(params);

	  if (!result.result.length) {
        this.isNoData = true;
      } else {
        this.favList = result.result;
        this.isNoData = false;
      }

    },
    toggleModal() {
      this.toggleFavModal();
      !this.isShowFavModal && this.getFavLeagueListData();
    },
    async favHandler(id, isFav, titleType, name) {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
        leagueId: id,
      };

      const res = await this.unFavLeague(params);
      if (res.result.businessCode === -1) {
        //fail
        // level2.isFavMatch = true;
      } else {
        let paramsToastr = {
          isOpen: true,
          msg: name,
          isAddedFav: false,
        };

        this.toastrFavChangeEvent(paramsToastr);
        this.init();
      }
    },
  },
};
</script>

<style scoped></style>
